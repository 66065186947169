import React from 'react';
import { Link } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';

import { PageRouteConfig } from '../types/routes';
import * as styles from './nav-links.module.scss';

export const NavLink = ({
  label,
  title,
  path,
  isPrivate,
  isExternal,
  isNewTab,
  linkClassName,
  activeLinkClassName,
  className,
}: NavLinkProps) => {
  const { isAuthenticated } = useAuth0();

  if (isPrivate && !isAuthenticated) return null;

  const newTabAttrs = isNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <li className={className}>
      {isExternal ? (
        <a href={path} className={linkClassName} {...newTabAttrs}>
          {label || title}
        </a>
      ) : (
        <Link
          to={path}
          activeClassName={activeLinkClassName}
          className={linkClassName}
        >
          {label || title}
        </Link>
      )}
    </li>
  );
};

export interface NavLinkBaseProps {
  linkClassName?: string;
  activeLinkClassName?: string;
}

export interface NavLinkProps extends NavLinkBaseProps, PageRouteConfig {
  className?: string;
}

/**
 * Generic component to list navigation links with basic styling. Allows extra
 * classnames to be passed to various elements from a parent component to apply
 * custom styles.
 *
 * @param className - Custom classname for the list element
 * @param isExternal - Renders items as <a> instead of <Link>
 * @param isInline - Renders items in an inline list
 * @param itemClassName - Custom classname for the item elements
 * @param activeLinkClassName - Custom classname for the active link element
 * @param linkClassName - Custom classname for the link elements
 * @param items - Array of nav link objects
 */
const NavLinks = ({
  className,
  isInline,
  itemClassName,
  activeLinkClassName,
  linkClassName,
  items,
}: NavLinksProps) => {
  return (
    <ul
      className={classNames(styles.NavLinks, className, {
        [styles.NavLinksInline]: isInline,
      })}
    >
      {items.map((pageRouteConfig, index) => (
        <NavLink
          {...pageRouteConfig}
          key={index}
          activeLinkClassName={activeLinkClassName}
          linkClassName={linkClassName}
          className={classNames(styles.NavLinks__item, itemClassName)}
        />
      ))}
    </ul>
  );
};

export interface NavLinksProps extends NavLinkBaseProps {
  className?: string;
  isInline?: boolean;
  itemClassName?: string;
  items: PageRouteConfig[];
}

export default NavLinks;
