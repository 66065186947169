import { FormField, TeamControls } from '../types';
import { templates } from './templates';

// TODO: May eventually need to include logo in editable fields
export const EDITABLE_FIELD_NAMES = [
  'firstName',
  'lastName',
  'qualifications',
  'jobTitle',
  'department',
  'companyName',
  'email',
  'phone',
  'mobile',
  'streetAddress',
  'website',
  'linkedinUrl',
  'facebookUrl',
  'twitterUrl',
  'instagramUrl',
  'youtubeUrl',
  'tiktokUrl',
  'threadsUrl',
  'blueskyUrl',
  'mastodonUrl',
  'termsPrivacy',
] as const;

export const OPTIONS_FIELD_NAMES = [
  'template',
  'fontFamily',
  'fontSize',
] as const;

export const COLOR_FIELD_NAMES = [
  'baseColour',
  'highlightColour',
  'linkColour',
  'smallColour',
] as const;

export const HIDDEN_FIELD_NAMES = [
  'logoHeight',
  'logoWidth',
  'logoURL',
  'bannerURL',
] as const;

export const DESIGN_FIELD_NAMES = [
  ...OPTIONS_FIELD_NAMES,
  ...COLOR_FIELD_NAMES,
  ...HIDDEN_FIELD_NAMES,
  'logoLink',
  'bannerLink',
] as const;

export const FIELD_NAMES = [
  ...EDITABLE_FIELD_NAMES,
  ...DESIGN_FIELD_NAMES,
  'logo',
  'banner',
] as const;

export const FORM_FIELDS: FormField[] = [
  {
    id: 'firstName',
    name: 'firstName',
    label: 'First name',
    order: 1,
    value: 'Jo',
    type: 'text',
  },
  {
    id: 'lastName',
    name: 'lastName',
    label: 'Last name',
    order: 2,
    value: 'Smith',
    type: 'text',
  },
  {
    id: 'qualifications',
    name: 'qualifications',
    label: 'Qualifications / pronouns',
    order: 3,
    value: 'PhD',
    type: 'text',
  },
  {
    id: 'jobTitle',
    name: 'jobTitle',
    label: 'Job title',
    order: 4,
    value: 'Managing Director',
    type: 'text',
  },
  {
    id: 'department',
    name: 'department',
    label: 'Department',
    order: 5,
    value: '',
    type: 'text',
  },
  {
    id: 'companyName',
    name: 'companyName',
    label: 'Company name',
    order: 6,
    value: '',
    type: 'text',
  },
  {
    id: 'email',
    name: 'email',
    label: 'Email',
    order: 7,
    value: 'jo.smith@mirosign.com',
    type: 'email',
  },
  {
    id: 'phone',
    name: 'phone',
    label: 'Phone',
    order: 8,
    value: '02012 123 456',
    type: 'text',
  },
  {
    id: 'mobile',
    name: 'mobile',
    label: 'Mobile',
    order: 9,
    value: '07123 456 789',
    type: 'text',
  },
  {
    id: 'streetAddress',
    name: 'streetAddress',
    label: 'Street address',
    order: 10,
    value: '1 Company Road, London SW1A 1AA',
    type: 'text',
  },
  {
    id: 'website',
    name: 'website',
    label: 'Website',
    order: 11,
    value: 'https://mirosign.com',
    type: 'text',
  },
  {
    id: 'linkedinUrl',
    name: 'linkedinUrl',
    label: 'LinkedIn URL',
    order: 12,
    value: '',
    type: 'text',
  },
  {
    id: 'facebookUrl',
    name: 'facebookUrl',
    label: 'Facebook URL',
    order: 13,
    value: 'https://facebook.com',
    type: 'text',
  },
  {
    id: 'twitterUrl',
    name: 'twitterUrl',
    label: 'Twitter URL',
    order: 14,
    value: 'https://twitter.com',
    type: 'text',
  },
  {
    id: 'instagramUrl',
    name: 'instagramUrl',
    label: 'Instagram URL',
    order: 15,
    value: '',
    type: 'text',
  },
  {
    id: 'youtubeUrl',
    name: 'youtubeUrl',
    label: 'YouTube URL',
    order: 15.1,
    value: '',
    type: 'text',
  },
  {
    id: 'tiktokUrl',
    name: 'tiktokUrl',
    label: 'TikTok URL',
    order: 15.2,
    value: '',
    type: 'text',
  },
  {
    id: 'threadsUrl',
    name: 'threadsUrl',
    label: 'Threads URL',
    order: 15.3,
    value: '',
    type: 'text',
  },
  {
    id: 'blueskyUrl',
    name: 'blueskyUrl',
    label: 'Bluesky URL',
    order: 15.4,
    value: '',
    type: 'text',
  },
  {
    id: 'mastodonUrl',
    name: 'mastodonUrl',
    label: 'Mastodon URL',
    order: 15.5,
    value: '',
    type: 'text',
  },
  {
    id: 'termsPrivacy',
    name: 'termsPrivacy',
    label: 'Terms / privacy',
    order: 16,
    value:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra mollis leo, id consequat sapien ultrices in. Sed finibus ullamcorper turpis, in blandit orci aliquam et.',
    type: 'textarea',
  },
  {
    id: 'logo',
    name: 'logo',
    label: 'Logo upload',
    order: 17,
    type: 'file',
    accept: ['png', 'jpg', 'jpeg', 'gif'],
  },
  {
    id: 'logoURL',
    name: 'logoURL',
    label: 'Logo URL',
    order: 18,
    type: 'hidden',
    value: `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_NAME}/image/upload/assets/demo-logo.gif`,
  },
  {
    id: 'logoHeight',
    name: 'logoHeight',
    label: 'Logo Height',
    order: 19,
    type: 'hidden',
  },
  {
    id: 'logoWidth',
    name: 'logoWidth',
    label: 'Logo Width',
    order: 20,
    type: 'hidden',
  },
  {
    id: 'logoLink',
    name: 'logoLink',
    label: 'Logo link',
    order: 21,
    value: 'https://mirosign.com',
    type: 'text',
  },
  {
    id: 'banner',
    name: 'banner',
    label: 'Banner upload',
    order: 21.1,
    type: 'file',
    accept: ['png', 'jpg', 'jpeg', 'gif'],
  },
  {
    id: 'bannerURL',
    name: 'bannerURL',
    label: 'Banner URL',
    order: 21.2,
    type: 'hidden',
    value: `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_NAME}/image/upload/assets/demo-banner.gif`,
  },
  {
    id: 'bannerLink',
    name: 'bannerLink',
    label: 'Banner link',
    order: 21.3,
    value: 'https://mirosign.com',
    type: 'text',
  },
  {
    id: 'template',
    name: 'template',
    label: 'Template',
    order: 22,
    value: 'Template 1',
    type: 'radio',
    options: [...templates],
  },
  {
    id: 'baseColour',
    name: 'baseColour',
    label: 'Base colour',
    order: 23,
    value: '#000000',
    type: 'color',
  },
  {
    id: 'highlightColour',
    name: 'highlightColour',
    label: 'Highlight colour',
    order: 24,
    value: '#333333',
    type: 'color',
  },
  {
    id: 'linkColour',
    name: 'linkColour',
    label: 'Link colour',
    order: 25,
    value: '#3B3BB4',
    type: 'color',
  },
  {
    id: 'smallColour',
    name: 'smallColour',
    label: 'Small text colour',
    order: 26,
    value: '#555555',
    type: 'color',
  },
  {
    id: 'fontFamily',
    name: 'fontFamily',
    label: 'Font',
    order: 27,
    value: 'System Font',
    type: 'select',
    options: [
      'Arial / Helvetica',
      'Calibri',
      'Comic Sans',
      'Courier',
      'Georgia',
      'Lucida Console / Monaco',
      'Lucida Sans',
      'Palatino',
      'System Font',
      'Tahoma',
      'Times / Times New Roman',
      'Trebuchet',
      'Verdana',
    ],
  },
  {
    id: 'fontSize',
    name: 'fontSize',
    label: 'Font size',
    order: 28,
    value: 'Medium',
    type: 'radio',
    options: ['Small', 'Medium', 'Large'],
  },
];

export const TEAM_ID_VALIDATION = {
  SCHEMA: new RegExp(/^[a-z0-9_-]+$/),
  MIN: 3,
  MAX: 16,
};

export const DEFAULT_GENERATOR_CONFIG: TeamControls = {
  firstName: {
    isEnabled: true,
    isLocked: false,
  },
  lastName: {
    isEnabled: true,
    isLocked: false,
  },
  qualifications: {
    isEnabled: true,
    isLocked: false,
  },
  jobTitle: {
    isEnabled: true,
    isLocked: false,
  },
  department: {
    isEnabled: true,
    isLocked: false,
  },
  companyName: {
    isEnabled: true,
    isLocked: false,
  },
  email: {
    isEnabled: true,
    isLocked: false,
  },
  phone: {
    isEnabled: true,
    isLocked: false,
  },
  mobile: {
    isEnabled: true,
    isLocked: false,
  },
  streetAddress: {
    isEnabled: true,
    isLocked: false,
  },
  website: {
    isEnabled: true,
    isLocked: false,
  },
  linkedinUrl: {
    isEnabled: true,
    isLocked: false,
  },
  facebookUrl: {
    isEnabled: true,
    isLocked: false,
  },
  twitterUrl: {
    isEnabled: true,
    isLocked: false,
  },
  instagramUrl: {
    isEnabled: true,
    isLocked: false,
  },
  youtubeUrl: {
    isEnabled: true,
    isLocked: false,
  },
  tiktokUrl: {
    isEnabled: true,
    isLocked: false,
  },
  threadsUrl: {
    isEnabled: true,
    isLocked: false,
  },
  blueskyUrl: {
    isEnabled: true,
    isLocked: false,
  },
  mastodonUrl: {
    isEnabled: true,
    isLocked: false,
  },
  termsPrivacy: {
    isEnabled: true,
    isLocked: false,
  },
};
